<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    ref="swiperDom"
    class="swiper"
    :style="swiperStype"
  >
    <div
      class="swiper-content"
      :style="contentStype"
    >
      <div
        v-for="(item, index) of swiperConfig.list"
        :key="index"
        class="swiper-item"
        :style="swiperStype"
      >
        <slot
          :name="index"
        />
      </div>
    </div>
    <div class="arrows-box">
      <div class="arrows-box-item" @click="arrowsClick('minus')"></div>
      <div class="arrows-box-num" style="width: 100%;text-align: center;">{{ pageIndex + 1 }} / {{ pages }}</div>
      <div class="arrows-box-item" style="transform: rotate(-45deg);" @click="arrowsClick('plus')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Swiper',
  data() {
    return {
      swiperIndicator: false, // 是否显示指示点 默认不显示
      swiperIndicatorNumber: 0, // 指示点个数
      swiperCircular: false, // 是否收尾衔接 默认不衔接
      swiperHeight: 0, // swiper高度
      swiperWidth: 0, // swiper宽度
      swiperStype: {}, // swiper外层样式
      contentStype: '', // swiper滑动样式
      imageStyle: {}, // 图片样式
      isShowBtn: false, // 是否显示左右箭头按钮  默认不显示
      isShowBtnFlag: -1, // 鼠标划入划出标识
      swiperConfig: {
        // swiper配置
        type: '',
        width: 0,
        left: 0,
        index: 0,
        list: []
      },
      container: {
        // 实时记录Touch事件的 时间和距离
        time: { start: 0, end: 0 },
        touch: { start: 0, end: 0 },
        left: 0
      }
    }
  },
  props: {
    // 高
    height: {
      type: Number,
      required: true
    },
    // 高
    width: {
      type: Number,
      required: true
    },
    current: {
      type: Number,
      default: 0
    },
    pages: {
      type: String,
      required: true
    },
    pageIndex: {
      type: Number,
      required: true
    },
    config: {
      type: Object,
      default: {}
    },
    list: {
      type: Array,
      default: []
    }
  },
  watch: {
    current: {
      handler(n, o) {
        if (n !== this.swiperConfig.index) {
          if (n < 0 || n > this.swiperConfig.list.length - 1) {
            return
          }
          this.swiperConfig.index = n
          this.swiperConfig.left = -(n * this.swiperWidth)
          this.replaceStyle(-(n * this.swiperWidth), 0.4)
        }
      }
    }
  },

  created() {
    this.initialData()
  },

  methods: {
    // 初始化数据
    initialData() {
      this.swiperWidth = (document.documentElement.clientWidth || document.body.clientWidth) * 1.2
      this.swiperHeight = document.documentElement.clientHeight || document.body.clientHeight
      this.swiperStype = {
        height: this.swiperHeight + 'px',
        width: this.swiperWidth + 'px'
      }
      // 卡片
      const config = this.$props.config
      const num = config.cardNumber || 4
      this.swiperConfig.list = Array.from({ length: num }).map((item, index) => {
        return index
      })
      this.swiperConfig.width = num * this.swiperWidth
      // 根据索引计算父元素移动距离
      const currentIndex = this.$props.current > num ? num : this.$props.current
      this.swiperConfig.index = currentIndex
      this.swiperConfig.left = -(currentIndex * this.swiperWidth)
      // 设置元素父元素的宽度和移动距离
      this.replaceStyle(-(currentIndex * this.swiperWidth))
    },

    // 替换样式
    replaceStyle(left, inertiaTime = 0) {
      this.contentStype = `width: ${this.swiperConfig.width}px;transform: translate3d(${left}px, 0px, 0px);transition: all ${inertiaTime}s;`
    },

    // 点击左右箭头
    arrowsClick(flag) {
      this.$emit('SwiperFun', flag);
    }
  }
}
</script>

<style scoped lang="less">
.swiper {
  position: relative;
  overflow: hidden;
}
.swiper-content {
  height: 100%;
  display: flex;
}
.arrows-box{
  /* height: .6rem; */
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to top, rgba(0,100,255,.9), rgba(0,100,255,.0));
  display: flex;
  justify-content: space-between;
  padding: .25rem .3rem .2rem .3rem;
}
.arrows-box-item{
  height: .25rem;
  width: .25rem;
  border-right: 3px solid #64FBFB;
  border-bottom: 3px solid #64FBFB;
  transform: rotate(135deg);
  cursor: pointer;
}
.arrows-box-num{
  line-height: .25rem;
  color: #64FBFB;
  font-size: .25rem;
  letter-spacing: -.03rem
}
</style>
