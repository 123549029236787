<template>
  <div class="fast-box">
    <div class="fast-label" @click="itemItemShow">
      <slot name="title"></slot>
      <div class="search-item-item-show f-dong" :class="{'search-item-item-show-zhuan':fastShow}">
        <div class="arrows" :class="{'arrows-on':fastShow}"></div>
      </div>
    </div>
    <div class="fast-item-box" :style="`height: ${itemItemHeight}px;`">
      <div class="fast-item-box-son" ref="fastHeight">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  data () {
    return {
      itemItemHeight: 0,
      itemItemHeightReal: '',
      fastShow: false
    }
  },
  created () {},
  mounted () {
    this.itemItemHeightReal = this.$refs.fastHeight.offsetHeight
  },
  methods: {
    itemItemShow () {
      if (this.itemItemHeight === 0) {
        this.itemItemHeight = this.itemItemHeightReal
        this.fastShow = true
      } else {
        this.itemItemHeight = 0
        this.fastShow = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.fast-box{
  // border-radius: 4px;
  // border: 1px solid #CDD5DD;
  padding: 0 15px;
  background: rgba(250, 250, 250, .1);
  margin-top: .1rem;
}
.fast-label{
  position: relative;
  padding: 12px 30px 12px 5px;
  padding-right: 30px;
}
.search-item-item-show{
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 4px;
  color: #29A7E1;
}
.search-item-item-show-zhuan{
  transform: rotate(90deg);
}
.arrows{
  height: .15rem;
  width: .15rem;
  border-top: .03rem solid #ffffff;
  border-left: .03rem solid #ffffff;
  transform: rotate(135deg);
  position: absolute;
  top: .05rem;
  left: .06rem;
  transition: all 0.3s;
}
.arrows-on{
  border-top: .03rem solid #ffffff;
  border-left: .03rem solid #ffffff;
}
.fast-item-box{
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
}
.fast-item-box-son{
  // border-top: 1px solid #CDD5DD;
  padding: 0 .085rem .17rem .085rem;
}
.f-dong {
  transition: all .3s linear;
}
</style>
    