











































































































































import EZUIKit from 'ezuikit-js';
import Swiper from '@/components/swiper/SwiperNew.vue';
import Accordion from '@/components/accordion/Accordion.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import Worker from 'worker-loader!@/workers/worker'

import {
  sumEzopenList,
  getEzopen,
} from '@/http/adminApi';

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
    Swiper,
    Accordion,
    Dialog,
  },
})
export default class Video extends Vue {
  /**
   * 站点菜单相关
   */
  public navshow: boolean = false;
  public navend: boolean = false;
  public whArr: any = [];
  public zkzArr: any = [];
  public kfArr: any = [];
  public videoDialogTitle: string = '站点监控标题';
  public videoDialogShow: boolean = false;
  public videoDialogData: any = [
    {
      id: 'player0',
      name: '',
      state: 1, // 1 加载  2 正常  3 异常
      path: '',
      player: '',
    },
    {
      id: 'player1',
      name: '',
      state: 1, // 1 加载  2 正常  3 异常
      path: '',
      player: '',
    },
  ];

  /**
   * 轮播相关
   */
  public swiperConfig: any = {
    cardNumber: 1
  };
  public swiperPages: string= '--';
  public swiperCurrent: number = 0; // 本组件计算使用
  public swiperOldCurrent: number = -1; // 本组件计算使用
  public swiperHeight: number= document.documentElement.clientHeight || document.body.clientHeight
  public swiperWidth: number= document.documentElement.clientWidth || document.body.clientWidth
  public swiperArr: any[] = [];
  public originalSwiperArr: any[] = [];
  public isqiehuan: boolean = true // 是否可以切换页数
  public hitTectShow: boolean = false // 不可切换提示框是否显示

  // --------- 放大
  public fvideoDialogShow: boolean = false;
  public fvideoDialogTitle: string = '';
  public player: any = ''; // 播放监控
  public fplayerState: number = 1; // 1加载  2正常  3异常

  /**
   * 方法
   */

  // --------- 放大
  private created() {
    this.getData();
  }

  // 打开站点目录
  public menu() {
    this.navshow = true;
    this.navend = true;
  }

  // 关闭站点目录
  public nav_off() {
    const that = this;
    that.navshow = false;
    setTimeout(() => {
      that.navend = false;
    }, 230);
  }

  // 点击站点目录的站点
  public itemVideo(e: any, name: string) {
    this.isqiehuan = false
    this.videoDialogTitle = name + '视频监控';
    this.videoDialogShow = true;
    this.videoDialogData[0].name = name + '视频监控一';
    this.videoDialogData[0].path = e.deviceSerial1;
    this.videoDialogData[0].state = 1;
    this.videoDialogData[1].name = name + '视频监控二';
    this.videoDialogData[1].path = e.deviceSerial2;
    this.videoDialogData[1].state = 1;
    this.videoDialogData.forEach((value: any, index: number) => {
      getEzopen({ whId: value.path }).then((res: any) => {
        if (res) {
          value.player = new EZUIKit.EZUIKitPlayer({
            id: value.id, // 视频容器ID
            accessToken: res.result.token,
            url: res.result.url,
            audio: 0,
            handleSuccess: () => {
              value.state = 2;
            },
            handleError: () => {
              value.state = 3;
            },
          });
        } else {
          value.state = 3;
        }
      });
    });
  }

  // 关闭站点双监控弹窗
  public DialogShut(e: boolean) {
    this.videoDialogData.forEach((value: any, index: number) => {
      if (value.player) {
        value.player.stop();
        value.player = '';
        value.path = '';
      (document.getElementById(value.id) as any).innerHTML = '';
      }
    });
    this.isqiehuan = true
  }

  // 返回上衣页面
  public goBack() {
    this.$router.go(-1);
  }

  // 切换卡片后停止之前的视频
  private playerStop(obj: any) {
    obj.screen.forEach((val: any, ind: number) => {
      val.list.forEach((value: any, index: number) => {
        if (value.player) {
          // value.player.stop();
          value.player = '';
          value.state = 1;
          (document.getElementById(value.id) as any).innerHTML = '';
        }
      });
    });
  }

  // 切换卡片后加载当前的视频
  private playerPlay(obj: any) {
    obj.screen.forEach((val: any, ind: number) => {
      val.list.forEach((value: any, index: number) => {
        if (value.state !== 3) {
          if (value.vidoeUrl && value.videoToken) {
            value.player = new EZUIKit.EZUIKitPlayer({
              id: value.id, // 视频容器ID
              accessToken: value.videoToken,
              url: value.vidoeUrl,
              audio: 0,
              handleSuccess: (e: any) => {
                value.state = 2;
              },
              handleError: (e: any) => {
                value.state = 3;
              },
            });
          } else {
            getEzopen({ whId: value.path }).then((res: any) => {
              if (res) {
                value.vidoeUrl = res.result.url;
                value.videoToken = res.result.token;
                value.player = new EZUIKit.EZUIKitPlayer({
                  id: value.id, // 视频容器ID
                  accessToken: res.result.token,
                  url: res.result.url,
                  audio: 0,
                  handleSuccess: (e: any) => {
                    value.state = 2;
                  },
                  handleError: (e: any) => {
                    value.state = 3;
                  },
                });
              } else {
                value.state = 3;
              }
            });
          }
        } else {
          (document.getElementById(value.id) as any).innerHTML = '';
          value.player = '';
        }
      });
    });
    setTimeout(() => {
      this.isqiehuan = true
    }, 5000);
  }

  // 切换卡片
  public SwiperFun(e: string) {
    if (!this.isqiehuan) {
      this.hitTectShow = true
      setTimeout(() => {
        this.hitTectShow = false
      }, 1000)
      return
    }
    this.isqiehuan = false
    let index = this.swiperCurrent
    if (e === 'plus') {
      if (this.swiperCurrent < this.originalSwiperArr.length - 1) {
        index += 1
      } else {
        index = 0
      }
    } else {
      if (this.swiperCurrent === 0) {
        index = this.originalSwiperArr.length - 1
      } else {
        index -= 1
      }
    }
    this.swiperOldCurrent = this.swiperCurrent
    this.swiperCurrent = index
    this.playerStop(this.swiperArr[0]);
    // this.$nextTick(() => {
      this.swiperArr = [this.originalSwiperArr[this.swiperCurrent]]
      this.playerPlay(this.swiperArr[0]);
    // })
  }

  // 获取列表后  首次获取监控视频流
  private getPlayer(obj: any) {
    obj.screen.forEach((val: any, ind: number) => {
      val.list.forEach((value: any, index: number) => {
        if (!value.path) {
          value.state = 3;
        } else if (!value.player) {
          getEzopen({ whId: value.path }).then((res: any) => {
            if (res) {
              value.vidoeUrl = res.result.url;
              value.videoToken = res.result.token;
              value.player = new EZUIKit.EZUIKitPlayer({
                id: value.id, // 视频容器ID
                accessToken: res.result.token,
                url: res.result.url,
                audio: 0,
                handleSuccess: (e: any) => {
                  value.state = 2;
                },
                handleError: (e: any) => {
                  value.state = 3;
                },
              });
            } else {
              value.state = 3;
            }
          });
        }
      });
    });
    setTimeout(() => {
      this.isqiehuan = true
    }, 5000);
  }
  // 计时函数
  private jishi() {
    setTimeout(() => {
      if (this.isqiehuan) {
        this.SwiperFun('plus')
      }
      this.jishi()
    }, this.$route.query.time ? Number(this.$route.query.time) : 30000);
  }

  // 获取列表
  private getData() {
    console.log(this.$route.query.time)
    const that = this;
    that.$store.commit('setLoading', true);
    sumEzopenList({}).then((res: any) => {
      that.$store.commit('setLoading', false);
      if (res) {
        that.whArr = res.result.wh;
        that.zkzArr = res.result.zkz;
        that.kfArr = res.result.kf;
        const data = res.result.wh.concat(res.result.zkz).concat(res.result.kf);
        const arr: any = [];
        data.forEach((val: any, ind: number) => {
          if (val.openListVos.isRecommect === 1) {
            arr.push({
              openListVos: [
                {
                  id: 'video' + val.openListVos.deviceSerial1,
                  name: `${val.name}视频监控一`,
                  state: 1, // 1 加载  2 正常  3 异常
                  path: val.openListVos.deviceSerial1,
                  player: '',
                  vidoeUrl: '',
                  videoToken: '',
                },
                {
                  id: 'video' + val.openListVos.deviceSerial2,
                  name: `${val.name}视频监控二`,
                  state: 1, // 1 加载  2 正常  3 异常
                  path: val.openListVos.deviceSerial2,
                  player: '',
                  vidoeUrl: '',
                  videoToken: '',
                },
              ],
            });
          }
        });
        const arra: any = [];
        arr.forEach((val: any, ind: number) => {
          if (ind === 0) {
            arra.push({
              screen: [
                {
                  list: val.openListVos,
                },
              ],
            });
          } else {
            if (isInteger((ind) / 3)) {
              arra.push({
                screen: [
                  {
                    list: val.openListVos,
                  },
                ],
              });
            } else {
              arra[a(ind)].screen.push({
                list: val.openListVos,
              });
            }
          }
        });
        this.swiperPages = arra.length + ''
        this.originalSwiperArr = arra;
        that.swiperArr = [arra[0]];
        that.getPlayer(arra[0]);
        this.swiperCurrent = 0
        this.jishi()
        this.isqiehuan = false
        function a(e: any): any {
          const val = (e - 1) / 3;
          return parseInt(val + '');
        }
        function isInteger(obj: any): boolean {
          return typeof obj === 'number' && obj % 1 === 0;
        }
      }
    });
  }

  // 打开单个带声音的监控
  public danGeJianKong(item: any) {
    const that = this;
    this.fvideoDialogTitle = item.name;
    this.fvideoDialogShow = true;
    this.isqiehuan = false
    setTimeout(() => {
      that.player = new EZUIKit.EZUIKitPlayer({
        id: 'fangda_video_box', // 视频容器ID
        accessToken: item.videoToken,
        url: item.vidoeUrl,
        audio: 1,
        // plugin: ['talk'],
        handleSuccess: (e: any) => {
          that.fplayerState = 2;
        },
        handleError: (e: any) => {
          that.fplayerState = 3;
        },
      });
    }, 500);
  }

  // 关闭单个带声音的监控弹窗
  public DialogShuta(e: boolean) {
    this.fplayerState = 1;
    if (this.player) {
      this.player.stop();
      this.player = '';
      (document.getElementById('fangda_video_box') as any).innerHTML = '';
      this.isqiehuan = true
    }
  }
}
